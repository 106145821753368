import $ from 'jquery';
import { games } from './games.json';

const gameWrp = $('#game_list_wrp');
let template = '<div class="game_list" style="margin: 16px 16px 0; visibility: visible; overflow: hidden;">';

games.forEach((game, index) => {
	// if (index === 0) {
	// 	template += '<div class="game_list" style="margin: 16px 16px 0; visibility: visible; overflow: hidden;">';
	// }
	template += `
	<a class="${game.gameClass}" href="/game.html?id=${game.id}" target="_self" style="background-image: url('gamelist/${game.id}/brick.jpg')">
		<h3 class="title">${game.title}</h3>
	</a>
	`;
	if (index > 0 && index%29 === 0) {
		// template += `
		// 	</div>
		// 	<div>
		// 	<ins class="adsbygoogle"
    //     style="display:block"
    //     data-ad-client="ca-pub-1002463960821147"
    //     data-ad-slot="9088193743"
    //     data-ad-format="auto"
    //     data-full-width-responsive="true"></ins>
    //     <script>
    //       (adsbygoogle = window.adsbygoogle || []).push({});
		// 	</script>
		// 	</div>
		// 	<div class="game_list" style="margin: 16px 16px 0; visibility: visible; overflow: hidden;">
		// `
		template += `
			</div>
			<div class="game_list" style="margin: 16px 16px 0; visibility: visible; overflow: hidden;">
		`
	}
})

template += '</div>'

console.log(template)

gameWrp.append(template)