import $ from 'jquery';

var maskContentTimer;

document.getElementById('maskContainer').onclick = function(e){
  if(e.toElement.className=='maskContainer'){
      toggleMaskMenu(false);
  }
}

function toggleMaskMenu(switcher){
  if(maskContentTimer) clearTimeout(maskContentTimer);
  var mask = document.getElementById('maskMenu');
  var maskContent = document.getElementById('maskContent');
  if(switcher){
      mask.className = 'mask show';
      maskContentTimer = setTimeout(function(){
          maskContent.className = 'maskContent show';
      },200);
  }else{
      mask.className = 'mask';
      maskContentTimer = setTimeout(function(){
          maskContent.className = 'maskContent';
      },200);
  }
}

$('#extBtn').click(() => {
  toggleMaskMenu(true)
});

$('#cancel_share_btn').click(() => {
  toggleMaskMenu(false)
});
